<template>
  <div style="width:100%">
    <div class="container">
      <img @click="back" class="back" src="../assets/images/back.png" alt="" />
      <p class="mymsg">我的积分</p>
    </div>
    <div class="neirong">
      <div class="middle" v-for="item in list" :key="item.id">
        <div class="middle-box">
          <img src="../assets/images/积分.png" alt="" />
          <div class="middle-center">
            <span class="score_text">{{ item.memo }}</span>
            <span class="score_time">{{ item.createtime }}</span>
          </div>
        </div>
        <p class="score">{{ item.score }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getScore } from '../services/home'
export default {
  data() {
    return {
      list: []
    }
  },
  async created() {
    const res = await getScore()
    const result = res.data.data.data
    result.forEach(e => {
      const time = this.$moment(parseInt(e.createtime * 1000)).format(
        'YYYY-MM-DD hh:mm:ss'
      )
      e.createtime = time
    })

    this.list = res.data.data.data
  },
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.back {
  z-index: 100;
}
.middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 0.1px solid #eeeeee;
}
.middle-box {
  width: 42vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.middle-center {
  display: flex;
  flex-direction: column;
}
.neirong {
  position: absolute;
  top: 44px;
  width: 93%;
  padding: 10px;
  min-height: 400px;
}
.score {
  color: #38a6ff;
}
.score_text {
  width: 120px;
  height: 20px;
  font-size: 14px;
  font-family: Arial, Verdana, Sans-serif;
  font-weight: bold;
  color: #666;
  line-height: 20px;
  margin-bottom: 3px;
}
.score_time {
  font-size: 12px;
  font-family: Arial, Verdana, Sans-serif;
  color: #999999;
  line-height: 17px;
}
</style>
